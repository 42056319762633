import React from 'react';
import { buildIconWithVariant } from '@icons/business/icon.utils';
import { MoonPhase } from '@meteo/types';
import { IconSizes, SvgPropsWithVariant, SVGVariant } from '../icon.types';

const getBig = (d: string): SVGVariant => ({
  method: fillColor => (
    <>
      <path d={d} fill={fillColor} fillRule="evenodd" />
    </>
  ),
  size: {
    height: 44,
    width: 44,
  },
});

function MoonIcon({
  variant = IconSizes.big,
  fillColor = '#2D3049',
  phase = MoonPhase.WaningCrescent,
  ...props
}: SvgPropsWithVariant & { phase?: MoonPhase }) {
  switch (phase) {
    case MoonPhase.FullMoon: {
      const big = getBig(
        'M22,7 C13.729,7 7,13.729 7,22 C7,30.271 13.729,37 22,37 C30.271,37 37,30.271 37,22 C37,13.729 30.271,7 22,7 M22,39 C12.626,39 5,31.374 5,22 C5,12.626 12.626,5 22,5 C31.374,5 39,12.626 39,22 C39,31.374 31.374,39 22,39'
      );
      return buildIconWithVariant(
        {
          fillColor,
          variant,
          ...props,
        },
        { big, small: big, tiny: big, xl: big }
      );
    }
    case MoonPhase.WaxingCrescent: {
      const big = getBig(
        'M22,38.9c9.3,0,16.9-7.5,16.9-16.9S31.3,5.1,22,5.1S5.1,12.7,5.1,22S12.7,38.9,22,38.9z M36,22 c0,7.7-6.3,14-14,14c-0.2,0-0.4,0-0.7,0c4.5-3,7.5-8.2,7.5-14c0-5.8-3-11-7.5-14c0.2,0,0.4,0,0.7,0C29.7,8,36,14.3,36,22z'
      );
      return buildIconWithVariant(
        {
          fillColor,
          variant,
          ...props,
        },
        { big, small: big, tiny: big, xl: big }
      );
    }
    case MoonPhase.FirstQuarter: {
      const big = getBig(
        'M38.9,22c0-9.3-7.5-16.9-16.9-16.9l0,0l0,0C12.7,5.1,5.1,12.7,5.1,22S12.7,38.9,22,38.9l0,0l0,0 C31.3,38.9,38.9,31.3,38.9,22z M22,36V8c7.7,0,14,6.3,14,14S29.7,36,22,36z'
      );
      return buildIconWithVariant(
        {
          fillColor,
          variant,
          ...props,
        },
        { big, small: big, tiny: big, xl: big }
      );
    }
    case MoonPhase.WaxingGibbous: {
      const big = getBig(
        'M38.9,22c0-7.6-5-14-11.8-16.1l0,0l0,0c-0.4-0.1-0.7-0.2-1.1-0.3c-0.1,0-0.2,0-0.3-0.1 c-0.7-0.2-1.5-0.3-2.2-0.3c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0-1.2,0C12.7,5.1,5.1,12.7,5.1,22S12.7,38.9,22,38.9c0.4,0,0.8,0,1.2,0 c0.1,0,0.2,0,0.3,0c0.8-0.1,1.5-0.2,2.2-0.3c0.1,0,0.2,0,0.3-0.1c0.4-0.1,0.7-0.2,1.1-0.3l0,0l0,0C33.9,36,38.9,29.6,38.9,22z M22.7,36c-4.5-3-7.5-8.2-7.5-14c0-5.8,3-11,7.5-14C30.1,8.4,36,14.5,36,22S30.1,35.6,22.7,36z'
      );
      return buildIconWithVariant(
        {
          fillColor,
          variant,
          ...props,
        },
        { big, small: big, tiny: big, xl: big }
      );
    }
    case MoonPhase.NewMoon: {
      const big = getBig(
        'M22,5.1C12.7,5.1,5.1,12.7,5.1,22S12.7,38.9,22,38.9S38.9,31.4,38.9,22S31.3,5.1,22,5.1L22,5.1z'
      );
      return buildIconWithVariant(
        {
          fillColor,
          variant,
          ...props,
        },
        { big, small: big, tiny: big, xl: big }
      );
    }
    case MoonPhase.WaningGibbous: {
      const big = getBig(
        'M5.1,22c0,7.6,5,14,11.9,16.1l0,0l0,0c0.4,0.1,0.7,0.2,1.1,0.3c0.1,0,0.2,0,0.3,0.1c0.7,0.2,1.5,0.3,2.3,0.3 c0.1,0,0.2,0,0.3,0c0.4,0,0.8,0,1.2,0c9.3,0,16.9-7.6,16.9-16.9S31.3,5.1,22,5.1c-0.4,0-0.8,0-1.2,0c-0.1,0-0.2,0-0.3,0 c-0.8,0.1-1.5,0.2-2.3,0.3c-0.1,0-0.2,0-0.3,0.1c-0.4,0.1-0.7,0.2-1.1,0.3l0,0l0,0C10.1,8,5.1,14.4,5.1,22z M21.3,8 c4.5,3,7.5,8.2,7.5,14c0,5.8-3,11-7.5,14c-7.4-0.3-13.4-6.5-13.4-14S13.9,8.3,21.3,8z'
      );

      return buildIconWithVariant(
        {
          fillColor,
          variant,
          ...props,
        },
        { big, small: big, tiny: big, xl: big }
      );
    }
    case MoonPhase.LastQuarter: {
      const big = getBig(
        'M5.1,22c0,9.3,7.6,16.9,16.9,16.9l0,0l0,0c9.3,0,16.9-7.6,16.9-16.9c0-9.3-7.6-16.9-16.9-16.9l0,0l0,0 C12.7,5.1,5.1,12.7,5.1,22z M22,7.9v28.1c-7.8,0-14.1-6.3-14.1-14.1C7.9,14.2,14.2,7.9,22,7.9z'
      );
      return buildIconWithVariant(
        {
          fillColor,
          variant,
          ...props,
        },
        { big, small: big, tiny: big, xl: big }
      );
    }
    case MoonPhase.WaningCrescent: {
      const big = getBig(
        'M22,5.1C12.7,5.1,5.1,12.7,5.1,22c0,9.3,7.6,16.9,16.9,16.9c9.3,0,16.9-7.6,16.9-16.9 C38.9,12.7,31.3,5.1,22,5.1z M7.9,22c0-7.7,6.3-14.1,14.1-14.1c0.2,0,0.4,0,0.7,0c-4.5,3-7.5,8.2-7.5,14c0,5.8,3,11,7.5,14 c-0.2,0-0.4,0-0.7,0C14.2,36.1,7.9,29.8,7.9,22z'
      );
      return buildIconWithVariant(
        {
          fillColor,
          variant,
          ...props,
        },
        { big, small: big, tiny: big, xl: big }
      );
    }
    default: {
      const switchTest: never = phase;
      return switchTest;
    }
  }
}

export { MoonIcon };
