import { Geopoint } from 'pleinchamp-api-client';
import { useEffect, useState } from 'react';
import { fromFeatureToCity } from '@meteo/business/meteo.utils';
import { City, PlcGeoFeature, StandardCity } from '@meteo/types';
import Axios, { AxiosRequestConfig } from 'axios';

const GEOGOUV_BASEURL = 'https://api-adresse.data.gouv.fr';

export type ApiGeoGouvReverseResponse = {
  type: 'FeatureCollection';
  version: 'draft';
  features: Array<{
    type: 'Feature';
    geometry: {
      type: 'Point';
      coordinates: Array<number>;
    };
    properties: {
      label: string;
      score: number;
      housenumber: string;
      id: string;
      type: 'housenumber';
      x: number;
      y: number;
      importance: number;
      name: string;
      postcode: string;
      citycode: string;
      city: string;
      context: string;
      street: string;
      distance: number;
    };
  }>;
  attribution: 'BAN';
  licence: 'ETALAB-2.0';
  limit: number;
};

type GeoGouvQueryParams = { queryString: string; type?: string; postcode?: string; citycode?: string };
export function searchLocalities(
  { queryString, type, postcode, citycode }: GeoGouvQueryParams,
  axiosOptions?: AxiosRequestConfig
) {
  if (!queryString || !queryString.length) {
    return Promise.resolve([]);
  }
  return Axios.get(
    `${GEOGOUV_BASEURL}/search/?q=${encodeURIComponent(queryString)}${type ? `&type=${type}` : ''}${
      postcode ? `&postcode=${postcode}` : ''
    }${citycode ? `&citycode=${citycode}` : ''}`,
    axiosOptions
  )
    .then(response =>
      (response.data.features as PlcGeoFeature[])
        .filter(feature => feature.type && feature.properties && feature.type === 'Feature')
        .map(feature => fromFeatureToCity(feature))
    )
    .catch(error => {
      if (!Axios.isCancel(error)) {
        throw error;
      }
      return [] as City[];
    });
}

export async function getCityFromCoordinates(coordinates: Geopoint): Promise<StandardCity | undefined> {
  const {
    data: { features },
  } = await Axios.get<ApiGeoGouvReverseResponse>(
    `${GEOGOUV_BASEURL}/reverse/?lon=${coordinates.longitude}&lat=${coordinates.latitude}`
  );

  if (features.length === 0) {
    return undefined;
  }

  return {
    geometry: coordinates,
    properties: features[0].properties,
    type: 'Feature',
  };
}

let useSearchLocalitiesSource = Axios.CancelToken.source();

export function useSearchLocalities(queryParams: GeoGouvQueryParams) {
  const [cities, setCities] = useState<City[]>([]);
  useEffect(() => {
    useSearchLocalitiesSource.cancel();
    useSearchLocalitiesSource = Axios.CancelToken.source();
    if (queryParams.queryString.length > 0) {
      searchLocalities(queryParams, {
        cancelToken: useSearchLocalitiesSource.token,
      }).then(setCities);
    }
    return () => {
      useSearchLocalitiesSource.cancel();
    };
  }, [...Object.values(queryParams)]);

  return cities;
}
