import { searchLocalities } from '@api/business/geogouv-api';
import { City } from '@meteo/types';
import { castUrlParamToString } from '@utils/url';

export const MyPositionAsCity: City = {
  geometry: null,
  properties: {
    citycode: '0',
    name: 'Ma position',
    postcode: '0',
  },
  type: 'Geolocation',
};

export async function recoverCityFromQuery(localityAsString: string): Promise<City | undefined> {
  const localityRegex = /^(\d{5})-(.*)$/;
  let match = localityAsString.match(localityRegex);
  if (match && match.length >= 3) {
    const postcode = match[1];
    const name = match[2];
    try {
      return (await searchLocalities({ postcode, queryString: name.replace('-', ' ') }))[0];
    } catch (e) {
      console.error('Error while recovering city details from url');
      return undefined;
    }
  }

  const geolocationRegex = /^ma-position$/;
  match = localityAsString.match(geolocationRegex);
  if (match) {
    return MyPositionAsCity;
  }

  return undefined;
}

export async function getCityFromUrl(locality?: string | string[]) {
  const localityAsString = castUrlParamToString(locality);
  const recoveredCity: City | undefined = localityAsString ? await recoverCityFromQuery(localityAsString) : undefined;
  return recoveredCity;
}
