import { ComponentPropsWithRef, CSSProperties } from 'react';

export interface SvgProps extends ComponentPropsWithRef<'svg'> {
  title?: string;
  fillColor?: CSSProperties['fill'];
  tabIndex?: number;
}

export interface SquareSvgProps extends Omit<SvgProps, 'width'> {
  size?: number;
}
export interface SvgPropsWithVariant extends SvgProps {
  variant?: IconSizes;
}

export enum IconSizes {
  tiny = 'tiny',
  small = 'small',
  big = 'big',
  xl = 'xl',
}

type DefaultSize = {
  width: number;
  height: number;
};

type SVGVariantMethod = (fillColor: SvgProps['fillColor']) => JSX.Element;

export type SVGVariants = {
  [key in IconSizes]: SVGVariant;
};

export type SVGVariant = {
  size: DefaultSize;
  method: SVGVariantMethod;
};
