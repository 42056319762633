import React from 'react';
import { buildIconWithVariant } from '@icons/business/icon.utils';
import { IconSizes, SvgPropsWithVariant, SVGVariant } from '../icon.types';

const small: SVGVariant = {
  method: fillColor => (
    <>
      <path
        d="M12 16a5.008 5.008 0 014.9 4H22v2H2v-2h5.1c.465-2.28 2.485-4 4.9-4zm0 2a3.006 3.006 0 00-2.829 2h5.658A3.006 3.006 0 0012 18zm7-5l1.414 1.414-2 2L17 15l2-2zM5.414 13l2 2L6 16.414l-2-2L5.414 13zM13 11v3h-2v-3h2zm0-9v3.174l1.293-1.295 1.414 1.414L12 9 8.293 5.293l1.414-1.414L11 5.172V2h2z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </>
  ),
  size: {
    height: 24,
    width: 24,
  },
};

const big: SVGVariant = {
  method: fillColor => (
    <>
      <path
        d="M22 33.25c2.829 0 5.182 2.056 5.657 4.75H16.343c.475-2.694 2.828-4.75 5.657-4.75zm0-2c3.934 0 7.185 2.949 7.678 6.75H40v2H4v-2h10.322c.493-3.801 3.744-6.75 7.678-6.75zm12.021-5.685l1.414 1.414-4.243 4.242-1.414-1.414 4.243-4.242zm-24.042 0l4.243 4.243-1.414 1.414-4.243-4.242 1.414-1.414zM23 21v6h-2v-6h2zm0-17v8.586l3.293-3.293 1.414 1.414L22 16.414l-5.707-5.707 1.414-1.414L21 12.586V4h2z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </>
  ),
  size: {
    height: 44,
    width: 44,
  },
};

function SunsetIcon({ variant = IconSizes.big, fillColor = '#2D3049', ...props }: SvgPropsWithVariant) {
  return buildIconWithVariant(
    {
      fillColor,
      variant,
      ...props,
    },
    { big, small, tiny: small, xl: big }
  );
}

export { SunsetIcon };
