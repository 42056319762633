import { locationSelectors } from './Location.store';
import { useCityCanonical } from './useCityCanonical';
import { Geopoint } from 'pleinchamp-api-client';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isGeolocation } from '@meteo/business/meteo.utils';
import { City, StandardCity } from '@meteo/types';
import { useGeolocation } from '@utils/geolocation.hook';

function getCoordinatesFromCity(city?: City, geolocationCoords?: Geopoint): Geopoint | undefined {
  if (city) {
    if (!isGeolocation(city) && city.geometry) {
      return {
        latitude: city.geometry.latitude,
        longitude: city.geometry.longitude,
      };
    }
    if (geolocationCoords) {
      return {
        latitude: geolocationCoords.latitude,
        longitude: geolocationCoords.longitude,
      };
    }
  }
  return undefined;
}

export function useLocation(defaultCityFromProps?: City) {
  const currentLocation = useSelector(locationSelectors.currentLocation);
  const defaultCity = defaultCityFromProps || currentLocation;
  const activeGeolocation = Boolean(defaultCity && isGeolocation(defaultCity));
  const {
    coords: geolocationCoords,
    denied: geolocationDenied,
    loading: geolocationLoading,
    errorMessage: geolocationError,
    city: geolocationCity,
  } = useGeolocation(activeGeolocation);

  const [city, setCity] = useState(defaultCity as StandardCity | undefined);
  const [coordinates, setCoordinates] = useState(city?.geometry);

  const canonical = useCityCanonical(city);

  useEffect(() => {
    setCoordinates(getCoordinatesFromCity(city, geolocationCoords));
  }, [geolocationCoords, city]);

  useEffect(() => {
    setCity(activeGeolocation ? geolocationCity : (defaultCity as StandardCity | undefined));
  }, [defaultCity, geolocationCity]);

  return {
    canonical,
    city,
    coordinates,
    geolocationError,
    isGeolocationDenied: geolocationDenied,
    isGeolocationLoading: geolocationLoading,
  };
}
