import React from 'react';
import { buildIconWithVariant } from '@icons/business/icon.utils';
import { IconSizes, SvgPropsWithVariant, SVGVariant } from '../icon.types';

const small: SVGVariant = {
  method: fillColor => (
    <>
      <path
        d="M15 15l.176.005A3 3 0 1112 18h2a1 1 0 101-1H2v-2h13zm3-10a4 4 0 110 8H2v-2h16c1.103 0 2-.897 2-2 0-1.104-.897-2-2-2s-2 .896-2 2h-2a4 4 0 014-4zm-8-2a3 3 0 110 6H2V7h8a1 1 0 10-1-1H7a3 3 0 013-3z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </>
  ),
  size: { height: 24, width: 24 },
};

const big: SVGVariant = {
  method: fillColor => (
    <>
      <path
        d="M26 25c2.757 0 5 2.243 5 5s-2.243 5-5 5-5-2.243-5-5h2c0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3H6v-2h20zm6-14c3.309 0 6 2.691 6 6s-2.691 6-6 6H6v-2h26c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4h-2c0-3.309 2.691-6 6-6zM19 9c2.757 0 5 2.243 5 5s-2.243 5-5 5H6v-2h13c1.654 0 3-1.346 3-3s-1.346-3-3-3-3 1.346-3 3h-2c0-2.757 2.243-5 5-5z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </>
  ),
  size: {
    height: 44,
    width: 44,
  },
};

const xl: SVGVariant = {
  method: fillColor => (
    <>
      <path
        d="M6 36h31a7 7 0 11-6.996 7.24L30 43h2c0 2.757 2.243 5 5 5s5-2.243 5-5a5.006 5.006 0 00-4.783-4.995L37 38H6v-2h31zm39-23a9 9 0 019 9 9 9 0 01-9 9H6v-2h39c3.86 0 7-3.141 7-7s-3.14-7-7-7-7 3.141-7 7h-2a9 9 0 019-9zm-19-3a7 7 0 01.24 13.996L26 24H6v-2h20c2.757 0 5-2.243 5-5s-2.243-5-5-5a5.006 5.006 0 00-4.995 4.783L21 17h-2a7 7 0 017-7z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </>
  ),
  size: {
    height: 60,
    width: 60,
  },
};

function WindIcon({ variant = IconSizes.big, fillColor = '#2D3049', ...props }: SvgPropsWithVariant) {
  return buildIconWithVariant(
    {
      fillColor,
      variant,
      ...props,
    },
    { big, small, tiny: small, xl }
  );
}

export { WindIcon };
