import { useRouter } from 'next/router';
import { useContext, useMemo } from 'react';
import { StandardCity } from '@meteo/types';
import { GeneralContext } from 'features/business/app.contexts';
import { getCityCanonical } from './meteo.utils';

export function useCityCanonical(city: StandardCity | undefined) {
  const router = useRouter();
  const { host } = useContext(GeneralContext);
  const canonical = useMemo(() => getCityCanonical(router, city), [router, city]);

  return `${host}${canonical}`;
}
