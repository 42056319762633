import React, { FC } from 'react';
import { PlcContentLoader } from '@components/Loaders/PlcContentLoader.component';

const WeatherCodeBigIconLoader: FC<{ uniqueKey: string; size?: number }> = ({ uniqueKey, size = 44 }) => {
  const circleRadius = size / 2;
  const innerMargin = 2;
  return (
    <PlcContentLoader height={size} preserveAspectRatio="xMidYMid meet" uniqueKey={uniqueKey} width={size}>
      <circle cx={circleRadius} cy={circleRadius} r={circleRadius - innerMargin} />
    </PlcContentLoader>
  );
};

export default WeatherCodeBigIconLoader;
