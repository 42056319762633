import React from 'react';
import { IconSizes, SvgPropsWithVariant, SVGVariants } from '@icons/icon.types';

export function buildIconWithVariant(
  { title, variant = IconSizes.small, fillColor, width, height, ...props }: SvgPropsWithVariant,
  svgVariants: SVGVariants
): JSX.Element {
  const { width: variantWidth, height: variantHeight } = svgVariants[variant].size;
  const viewBox = `0 0 ${variantWidth} ${variantHeight}`;

  const finalWidth = width || variantWidth;
  const finalHeight = height || variantHeight;

  return (
    <svg height={finalHeight} viewBox={viewBox} width={finalWidth} xmlns="http://www.w3.org/2000/svg" {...props}>
      {title && <title>{title}</title>}
      {svgVariants[variant].method(fillColor)}
    </svg>
  );
}
