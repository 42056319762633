/* eslint-disable no-nested-ternary */
import WeatherCodeBigIconLoader from './WeatherCodeBigLoader.component';
import React from 'react';
import { useTranslation } from '@locales/useTranslation.hook';
import { Tooltip } from '@material-ui/core';
import { mapApiWeatherCodeToWeatherCode } from '@meteo/business/meteo.weatherCodeMapping';
import { WeatherCodeIconProps } from '@meteo/types';
import dynamic from 'next/dynamic';
import { IconSizes } from '../../icon.types';

const WeatherCodeBigIcon = dynamic<WeatherCodeIconProps>(() => import('./WeatherCodeIllu.icon'), {
  loading: () => <WeatherCodeBigIconLoader uniqueKey="WeatherCodeBigIcon" />,
  ssr: false,
});
const WeatherCodeIllu = dynamic<WeatherCodeIconProps>(() => import('./WeatherCodeIllu.icon'), {
  ssr: false,
});

export function WeatherCodeIcon({
  variant = IconSizes.big,
  weatherCode,
  isLoading,
  night = false,
  ...props
}: WeatherCodeIconProps) {
  const { t } = useTranslation(['meteo']);
  const innerHtml =
    variant === IconSizes.xl ? (
      <WeatherCodeIllu
        night={night}
        weatherCode={mapApiWeatherCodeToWeatherCode(weatherCode)}
        {...props}
        variant={variant}
      />
    ) : isLoading ? (
      <WeatherCodeBigIconLoader uniqueKey="WeatherCodeBigIcon" />
    ) : (
      <WeatherCodeBigIcon
        night={night}
        weatherCode={mapApiWeatherCodeToWeatherCode(weatherCode)}
        {...props}
        variant={variant}
      />
    );

  return (
    <Tooltip enterDelay={500} title={t(`meteo.weather-code.${weatherCode}`) as string}>
      <div>{innerHtml}</div>
    </Tooltip>
  );
}
