import { Geopoint } from 'pleinchamp-api-client';
import { useEffect, useState } from 'react';
import { getCityFromCoordinates } from '@api/business/geogouv-api';
import { useTranslation } from '@locales/useTranslation.hook';
import { setLastCity } from '@meteo/business/meteo.utils';
import { City, StandardCity } from '@meteo/types';
import { isServer } from '@utils/server';

interface UseGeolocationResponse {
  city?: StandardCity;
  coords?: Geopoint;
  denied: boolean;
  errorMessage?: string;
  loading: boolean;
}

const useGeolocation = (active: boolean): UseGeolocationResponse => {
  const { t } = useTranslation(['meteo']);
  const geo = !isServer() && navigator && navigator.geolocation;
  const [coords, setCoords] = useState<Geopoint | undefined>();
  const [city, setCity] = useState<StandardCity | undefined>();
  const [denied, setDenied] = useState<boolean>(!geo);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(active);
  // Check permissions is browser compatible
  useEffect(() => {
    if (!isServer() && navigator && navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
        setDenied(permissionStatus.state === 'denied');
      });
    }
  }, []);

  async function updateCityFromCoords(coordinates: Geopoint) {
    try {
      const newCity = await getCityFromCoordinates(coordinates);

      if (newCity) {
        setCoords(coordinates);
        setCity(newCity);
      } else {
        setErrorMessage(t('meteo.error.unsupported'));
      }
    } catch (e) {
      setErrorMessage(e.message);
    }
  }

  // Get current position if active and not denied
  useEffect(() => {
    let mounted = true;
    if (geo && active && !denied) {
      const onChange: PositionCallback = ({ coords: newCoords }) => {
        if (active && mounted) {
          updateCityFromCoords({ latitude: newCoords.latitude, longitude: newCoords.longitude });
        }
      };

      const onError: PositionErrorCallback = error => {
        if (active) {
          // error.code = 1 is denied
          if (error.code === 1) {
            setDenied(true);
            setErrorMessage(t('meteo.error.geolocation-denied'));
          } else {
            setDenied(false);
            setErrorMessage(t('meteo.error.geolocation'));
            console.error('Error while retrieving geolocation: ', error.message);
          }
          setLoading(false);
        }
      };

      geo.getCurrentPosition(onChange, onError, {
        enableHighAccuracy: false,
        maximumAge: 0,
        timeout: Infinity,
      });
      setLoading(false);
    } else {
      setLoading(false);
      setCoords(undefined);
      setCity(undefined);
      setErrorMessage(undefined);
    }

    return function cleanup() {
      mounted = false;
    };
  }, [active, denied, geo]);

  /**
   * The only way i found to fix Ma position store in the cookie.
   * I cast the fetch city to City to store it in the cookie in order to have
   * all the metrics available in the page meteo/
   */
  if (city && !denied) {
    const MyPositionAsCity: City = {
      geometry: city?.geometry as Geopoint,
      properties: {
        city: city?.properties.city as string,
        citycode: city?.properties.citycode as string,
        context: city?.properties.context as string,
        label: city?.properties.label as string,
        name: city?.properties.name as string,
        postcode: city?.properties.postcode as string,
      },
      type: 'Feature',
    };

    setLastCity(MyPositionAsCity);
  }

  return { city, coords, denied, errorMessage, loading };
};

export { useGeolocation };
