import React from 'react';
import { buildIconWithVariant } from '@icons/business/icon.utils';
import { IconSizes, SvgPropsWithVariant, SVGVariant } from '../icon.types';

const big: SVGVariant = {
  method: fillColor => (
    <>
      <path
        d="M15 29c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2m0-23c2.206 0 4 1.794 4 4v15.255A7.027 7.027 0 0122 31c0 3.859-3.14 7-7 7s-7-3.141-7-7a7.027 7.027 0 013-5.745V10c0-2.206 1.794-4 4-4zm0 2c-1.103 0-2 .897-2 2v16.38l-.5.288A5.021 5.021 0 0010 31c0 2.757 2.243 5 5 5s5-2.243 5-5c0-1.78-.958-3.439-2.5-4.332l-.5-.288V10c0-1.103-.897-2-2-2zm1 2v17.127c1.724.445 3 2.012 3 3.873 0 2.206-1.794 4-4 4s-4-1.794-4-4a4.007 4.007 0 013-3.874V10h2zm20 12v2h-8v-2h8zm-3-11v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </>
  ),
  size: {
    height: 44,
    width: 44,
  },
};

const xl: SVGVariant = {
  method: fillColor => (
    <>
      <path
        d="M25 45c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2m0-37a4.013 4.013 0 013.995 3.801L29 12v22.946c2.961 1.474 5 4.523 5 8.054a9 9 0 01-9 9 9 9 0 01-9-9c0-3.427 1.92-6.4 4.741-7.92l.259-.134V12c0-2.2 1.8-4 4-4zm0 2a2.002 2.002 0 00-1.995 1.85L23 12v24.186l-1.109.551A6.962 6.962 0 0018 43c0 3.859 3.14 7 7 7s7-3.141 7-7a6.958 6.958 0 00-3.654-6.14l-.237-.123L27 36.186V12c0-1.103-.897-2-2-2zm1 3v26.142A3.99 3.99 0 0129 43a4 4 0 01-8 0 3.99 3.99 0 013-3.858V13h2zm18 9v2h-8v-2h8zm-3-11v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </>
  ),
  size: {
    height: 60,
    width: 60,
  },
};

function TempMinMax({ variant = IconSizes.big, fillColor = '#2D3049', ...props }: SvgPropsWithVariant) {
  return buildIconWithVariant(
    {
      fillColor,
      variant,
      ...props,
    },
    { big, small: big, tiny: big, xl }
  );
}

export { TempMinMax };
