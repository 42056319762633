import React from 'react';
import { buildIconWithVariant } from '@icons/business/icon.utils';
import { IconSizes, SvgPropsWithVariant, SVGVariant } from '../icon.types';

const small: SVGVariant = {
  method: fillColor => (
    <>
      <path
        d="M9.001 19v3H7v-3h2.001zM17 2v17h-2V2h2zm-7.999.001V17H7V2.001h2.001zM5 2v13H3V2h2zm8 10v2h-2v-2h2zm8-10v10h-2V2h2zm-8 0v8h-2V2h2z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </>
  ),
  size: {
    height: 24,
    width: 24,
  },
};

const big: SVGVariant = {
  method: fillColor => (
    <>
      <path
        d="M22.485 6c-5.245 0-9.597 3.737-10.49 8.658C8.62 15.046 6 17.871 6 21.305 6 25.002 9.039 28 12.788 28h19.879C35.612 28 38 25.645 38 22.739c0-2.76-2.157-5.02-4.898-5.239.03-.322.05-.648.05-.979C33.152 10.711 28.376 6 22.485 6M15 30v8h-2v-8h2zm4 0v8h-2v-8h2zm4 0v8h-2v-8h2zm4 0v8h-2v-8h2zm4 0v8h-2v-8h2zM22.485 8c4.779 0 8.667 3.823 8.667 8.521 0 .236-.014.496-.042.792l-.19 2.019 2.023.161c1.714.138 3.057 1.563 3.057 3.246C36 24.537 34.505 26 32.667 26h-19.88C10.148 26 8 23.894 8 21.305c0-2.38 1.816-4.383 4.224-4.659l1.474-.17.265-1.46C14.7 10.95 18.285 8 22.485 8z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </>
  ),
  size: {
    height: 44,
    width: 44,
  },
};

const xl: SVGVariant = {
  method: fillColor => (
    <>
      <path
        d="M30.666 8c-7.212 0-13.195 5.097-14.422 11.807C11.604 20.336 8 24.188 8 28.869 8 33.912 12.178 38 17.334 38h27.332C48.716 38 52 34.788 52 30.826c0-3.764-2.965-6.846-6.736-7.144.042-.44.07-.884.07-1.334C45.334 14.424 38.766 8 30.666 8M19 41v11h-2V41h2zm6 0v11h-2V41h2zm6.001 0v11H29V41h2.001zM37 41v11h-2V41h2zm6 0v11h-2V41h2zM30.666 10c6.985 0 12.668 5.539 12.668 12.348 0 .343-.02.718-.06 1.146l-.191 2.022 2.023.16c2.744.217 4.893 2.479 4.893 5.15C50 33.679 47.607 36 44.666 36H17.334c-4.044 0-7.335-3.198-7.335-7.131 0-3.613 2.782-6.655 6.47-7.075l1.474-.168.267-1.459C19.29 14.275 24.527 10 30.666 10z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </>
  ),
  size: {
    height: 60,
    width: 60,
  },
};

function RainIcon({ variant = IconSizes.big, fillColor = '#2D3049', ...props }: SvgPropsWithVariant) {
  return buildIconWithVariant(
    {
      fillColor,
      variant,
      ...props,
    },
    { big, small, tiny: small, xl }
  );
}

export { RainIcon };
