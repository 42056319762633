import { SkyCode } from 'pleinchamp-api-client';
import { WeatherCode } from '@meteo/types';

const WeatherCodeMap = new Map([
  [-9999, 2],
  [-8, 2],
  [-7, 8],
  [-6, 21],
  [-5, 18],
  [-4, 5],
  [-3, 8],
  [-2, 6],
  [-1, 6],
  [0, 1],
  [1, 1],
  [2, 2],
  [3, 2],
  [4, 2],
  [5, 3],
  [6, 3],
  [7, 3],
  [8, 4],
  [9, 4],
  [10, 5],
  [11, 5],
  [12, 5],
  [13, 17],
  [14, 11],
  [15, 11],
  [16, 11],
  [17, 17],
  [18, 22],
  [19, 0],
  [20, 7],
  [21, 6],
  [22, 8],
  [23, 9],
  [24, 18],
  [25, 11],
  [26, 14],
  [27, 15],
  [28, 0],
  [29, 12],
  [30, 0],
  [31, 22],
  [32, 0],
  [33, 0],
  [34, 0],
  [35, 0],
  [36, 8],
  [37, 8],
  [38, 8],
  [39, 8],
  [40, 5],
  [41, 5],
  [42, 5],
  [43, 5],
  [44, 5],
  [45, 5],
  [46, 5],
  [47, 5],
  [48, 5],
  [49, 5],
  [50, 7],
  [51, 7],
  [52, 10],
  [53, 10],
  [54, 10],
  [55, 10],
  [56, 18],
  [57, 18],
  [58, 10],
  [59, 10],
  [60, 6],
  [61, 6],
  [62, 6],
  [63, 6],
  [64, 6],
  [65, 6],
  [66, 18],
  [67, 18],
  [68, 9],
  [69, 9],
  [70, 8],
  [71, 8],
  [72, 8],
  [73, 8],
  [74, 8],
  [75, 8],
  [76, 8],
  [77, 8],
  [78, 8],
  [79, 8],
  [80, 11],
  [81, 11],
  [82, 6],
  [83, 15],
  [84, 15],
  [85, 14],
  [86, 14],
  [87, 19],
  [88, 19],
  [89, 19],
  [90, 19],
  [91, 16],
  [92, 16],
  [93, 13],
  [94, 13],
  [95, 12],
  [96, 20],
  [97, 12],
  [98, 12],
  [99, 20],
]);

export function mapApiWeatherCodeToWeatherCode(wc?: number): WeatherCode {
  if (wc === undefined || wc === null) {
    return WeatherCode.ClearSkies;
  }

  const weatherIconCode: WeatherCode | undefined = WeatherCodeMap.get(wc);
  return weatherIconCode ? weatherIconCode : WeatherCode.ClearSkies;
}

export function mapSkyCodeToWeatherCode(skyCode: SkyCode): WeatherCode {
  switch (skyCode) {
    case SkyCode.Clear:
      return WeatherCode.ClearSkies;
    case SkyCode.PartlyCloudy:
      return WeatherCode.PartlyCloudy;
    case SkyCode.Overcast:
      return WeatherCode.Overcast;
    case SkyCode.Mist:
      return WeatherCode.Mist;
    case SkyCode.Rain:
      return WeatherCode.Rain;
    case SkyCode.Snowy:
      return WeatherCode.Snow;
    case SkyCode.Storm:
      return WeatherCode.Thunderstorms;
    default:
      throw new Error(`Unknow sky code ${skyCode}`);
  }
}
