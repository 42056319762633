import React from 'react';
import { buildIconWithVariant } from '@icons/business/icon.utils';
import { IconSizes, SvgPropsWithVariant, SVGVariant } from '../icon.types';

const small: SVGVariant = {
  method: fillColor => (
    <>
      <path
        d="M12 16a5.008 5.008 0 014.9 4H22v2H2v-2h5.1c.465-2.28 2.485-4 4.9-4zm0 2a3.006 3.006 0 00-2.829 2h5.658A3.006 3.006 0 0012 18zm7-5l1.414 1.414-2 2L17 15l2-2zM5.414 13l2 2L6 16.414l-2-2L5.414 13zM13 11v3h-2v-3h2zm-1-9l.998 1H13v.001l2.707 2.706-1.414 1.414L13 5.828V9h-2V5.826L9.707 7.121 8.293 5.707 12 2z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </>
  ),
  size: {
    height: 24,
    width: 24,
  },
};

const big: SVGVariant = {
  method: fillColor => (
    <>
      <path
        d="M22 33.251c2.829 0 5.182 2.055 5.657 4.749H16.343c.475-2.694 2.828-4.749 5.657-4.749zm0-2c3.934 0 7.185 2.948 7.678 6.749H40v2H4v-2h10.322c.493-3.801 3.744-6.749 7.678-6.749zM9.979 25.566l4.243 4.242-1.414 1.414-4.243-4.242 1.414-1.414zm24.042 0l1.414 1.414-4.243 4.242-1.414-1.414 4.243-4.242zM23 21v6h-2v-6h2zM22 4.586l5.707 5.707-1.414 1.414L23 8.414V17h-2V8.414l-3.293 3.293-1.414-1.414L22 4.586z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </>
  ),
  size: {
    height: 44,
    width: 44,
  },
};

function SunriseIcon({ variant = IconSizes.big, fillColor = '#2D3049', ...props }: SvgPropsWithVariant) {
  return buildIconWithVariant(
    {
      fillColor,
      variant,
      ...props,
    },
    { big, small, tiny: small, xl: big }
  );
}

export { SunriseIcon };
