import { motion } from 'framer-motion';
import React, { ComponentProps, ComponentPropsWithRef, FC } from 'react';
import { IconSizes } from '@components/icons/icon.types';
import { HumidityPercentIcon } from '@components/icons/meteo/HumidityPercent.icon';
import { MoonIcon } from '@components/icons/meteo/Moon.icon';
import { RainIcon } from '@components/icons/meteo/Rain.icon';
import { SunriseIcon } from '@components/icons/meteo/Sunrise.icon';
import { SunsetIcon } from '@components/icons/meteo/Sunset.icon';
import { TempMinMax } from '@components/icons/meteo/TempMinMax.icon';
import { WindIcon } from '@components/icons/meteo/Wind.icon';
import { WindDirectionIcon } from '@components/icons/meteo/WindDirection.icon';
import { PlcContentLoader } from '@components/Loaders/PlcContentLoader.component';
import { PlcTextLoader } from '@components/Loaders/PlcTextLoader.component';
import { Text } from '@components/Text/Text.component';
import { useTranslation } from '@locales/useTranslation.hook';
import { TooltipProps } from '@material-ui/core';
import { MeteoMetric, MoonPhase } from '@meteo/types';
import { Breakpoint, useBreakpoint } from '@styles/breakpoints';
import { Spacing } from '@styles/Spacing.style';
import { TextStyle } from '@styles/Text.style';
import { formatNumberWithComma } from '@utils/number';
import classnames from 'classnames';
import dynamic from 'next/dynamic';

import './MeteoSingleMetric.scss';

const Tooltip = dynamic<TooltipProps>(() => import('@material-ui/core/').then(module => module.Tooltip));

interface Props extends ComponentPropsWithRef<'div'> {
  type: MeteoMetric;
  value: string;
  subValue?: string;
  isSmall?: boolean;
  isLoading?: boolean;
  options?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
    angle?: number;
    moonPhase?: MoonPhase;
  };
}

function buildIconFromType(type: MeteoMetric, variant: IconSizes, iconSize: number, options: Props['options']) {
  switch (type) {
    case MeteoMetric.RainLevel: {
      return <RainIcon height={iconSize} variant={variant} width={iconSize} />;
    }
    case MeteoMetric.MinMaxTemperature: {
      return <TempMinMax height={iconSize} variant={variant} width={iconSize} />;
    }
    case MeteoMetric.WindSpeed: {
      return <WindIcon height={iconSize} variant={variant} width={iconSize} />;
    }
    case MeteoMetric.Sunrise: {
      return <SunriseIcon height={iconSize} variant={variant} width={iconSize} />;
    }
    case MeteoMetric.Sunset: {
      return <SunsetIcon height={iconSize} variant={variant} width={iconSize} />;
    }
    case MeteoMetric.MoonPhase: {
      const moonPhase = options && options.moonPhase ? options.moonPhase : undefined;
      return <MoonIcon height={iconSize} phase={moonPhase} variant={variant} width={iconSize} />;
    }
    case MeteoMetric.Humidity: {
      return <HumidityPercentIcon height={iconSize} variant={variant} width={iconSize} />;
    }
    case MeteoMetric.WindDirection: {
      const angle = options && options.angle ? options.angle : 0;
      const animate = {
        rotate: `${360 * 2 - 180 + angle}deg`,
      };
      return (
        <motion.div animate={animate} transition={{ delay: 1, duration: 0.7 }}>
          <WindDirectionIcon height={iconSize} width={iconSize} />
        </motion.div>
      );
    }
    default: {
      const switchComplete: never = type;
      return switchComplete;
    }
  }
}

function formatMeteoMetric(value: string, metricType: MeteoMetric) {
  if (metricType === MeteoMetric.RainLevel) {
    return formatNumberWithComma(value, 1);
  }
  return value;
}

const MeteoSingleMetric: FC<Props> = ({ type, value, subValue, className, isSmall, isLoading, options, ...rest }) => {
  const rootClass = classnames('meteo-single-metric', isSmall ? 'small' : '', className);
  const { t } = useTranslation(['meteo']);
  const breakpoint = useBreakpoint();
  const iconVariant: IconSizes = isSmall ? IconSizes.big : IconSizes.xl;
  let iconSize: number;
  let textVariant: ComponentProps<typeof Text>['variant'] | undefined;
  if (breakpoint === Breakpoint.l || breakpoint === Breakpoint.s) {
    if (isSmall) {
      iconSize = 36;
      textVariant = 'h6';
    } else {
      iconSize = 56;
      textVariant = 'h6';
    }
  } else if (isSmall) {
    iconSize = 44;
    textVariant = 'h6';
  } else {
    iconSize = 60;
    textVariant = 'h5';
  }

  if (/undefined/.test(value)) {
    return null;
  }

  return (
    <div className={rootClass} {...rest}>
      {!isLoading ? (
        <>
          <Tooltip enterDelay={500} placement="top" title={t(`meteo.metric.${type}`) as string}>
            <div className="metric-icon">{buildIconFromType(type, iconVariant, iconSize, options)}</div>
          </Tooltip>
          <Text alignment="center" className="metric-label" tag="span" variant={textVariant}>
            {formatMeteoMetric(value, type)}
          </Text>
          {subValue && !/undefined/.test(subValue) && (
            <Text alignment="center" className="metric-sub-label" flavour="grey" tag="span" variant="h6">
              {subValue}
            </Text>
          )}
        </>
      ) : (
        <MeteoSingleMetricsLoader isSmall={isSmall} uniqueKey={`meteo-${type}-metric`} />
      )}
    </div>
  );
};

const MeteoSingleMetricsLoader: FC<{ isSmall?: Props['isSmall']; uniqueKey: string }> = ({ isSmall, uniqueKey }) => {
  const iconSize = isSmall ? 44 : 60;
  const textVariant = isSmall ? 'h6' : 'h5';
  const iconRadius = iconSize / 2 - 2;
  const textWidth = iconSize * 1.2;
  const width = isSmall ? textWidth : textWidth + 2 * Spacing.xxs;
  const height = iconSize + TextStyle[textVariant].lineHeight;
  return (
    <PlcContentLoader className="metric-label" fixedWidth height={height} uniqueKey={uniqueKey} width={width}>
      <circle cx={width / 2} cy={iconSize / 2} r={iconRadius} />
      <PlcTextLoader
        className="metric-label"
        variant={textVariant}
        width={textWidth}
        x={(width - textWidth) / 2}
        y={iconSize}
      />
    </PlcContentLoader>
  );
};

export { MeteoSingleMetric };
