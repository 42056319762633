import React from 'react';
import { buildIconWithVariant } from '@icons/business/icon.utils';
import { IconSizes, SvgPropsWithVariant, SVGVariant } from '../icon.types';

const small: SVGVariant = {
  method: fillColor => (
    <>
      <path
        d="M12 2c6.036 6.782 9.251 10.286 7.545 15.485 0 0-1.509 5.515-7.545 5.515s-7.545-5.515-7.545-5.515C2.749 12.286 5.964 8.782 12 2zm0 3.014c-5.035 5.711-6.748 8.19-5.708 11.647l.063.2.016.048.013.048C6.501 17.357 7.688 21 12 21c4.214 0 5.443-3.48 5.61-4.026l.01-.033.009-.031.016-.049c1.168-3.562-.512-6.025-5.645-11.847zm2.293 6.279l1.414 1.414-6 6-1.414-1.414 6-6zM14 16a1 1 0 110 2 1 1 0 110-2zm-4-4a1 1 0 110 2 1 1 0 110-2z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </>
  ),
  size: {
    height: 24,
    width: 24,
  },
};

const big: SVGVariant = {
  method: fillColor => (
    <>
      <path
        d="M22 4c10.563 11.868 16.189 18 13.203 27.099 0 0-2.64 8.901-13.203 8.901-10.563 0-13.203-8.901-13.203-8.901C5.812 22 11.437 15.868 22 4zm0 3.01C12.276 17.968 8.396 23.026 10.608 30.196l.089.28.009.027.008.027C10.805 30.825 13.125 38 22 38c8.83 0 11.193-7.174 11.283-7.462l.003-.008.008-.027.009-.027C35.705 23.156 31.851 18.111 22 7.01zM26 26a3 3 0 110 6 3 3 0 010-6zm.293-6.707l1.414 1.414-10 10-1.414-1.414 10-10zM26 28a1 1 0 100 2 1 1 0 000-2zm-8-10a3 3 0 110 6 3 3 0 010-6zm0 2a1 1 0 100 2 1 1 0 000-2z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </>
  ),
  size: {
    height: 44,
    width: 44,
  },
};

function HumidityPercentIcon({ variant = IconSizes.big, fillColor = '#2D3049', ...props }: SvgPropsWithVariant) {
  return buildIconWithVariant(
    {
      fillColor,
      variant,
      ...props,
    },
    { big, small, tiny: small, xl: big }
  );
}

export { HumidityPercentIcon };
