import React, { FC, forwardRef } from 'react';
import { buildIconWithVariant } from '@icons/business/icon.utils';
import { IconSizes, SvgPropsWithVariant, SVGVariant } from '../icon.types';

const small: SVGVariant = {
  method: fillColor => (
    <path
      d="M11.978 4.691L6.053 19.546l5.942-3.681 5.942 3.681-5.959-14.855z"
      fill="none"
      fillRule="evenodd"
      stroke={fillColor}
      strokeWidth="2"
    />
  ),
  size: {
    height: 24,
    width: 24,
  },
};

const big: SVGVariant = {
  method: fillColor => (
    <path
      d="M22.462 8.57L11.09 35.606l11.403-6.7 11.41 6.703L22.462 8.57z"
      fill="none"
      fillRule="evenodd"
      stroke={fillColor}
      strokeWidth="2"
    />
  ),
  size: {
    height: 44,
    width: 44,
  },
};

const WindDirectionIcon: FC<SvgPropsWithVariant> = forwardRef(
  ({ variant = IconSizes.big, fillColor = '#2D3049', ...props }, svgRef) => {
    return buildIconWithVariant(
      {
        fillColor,
        ref: svgRef,
        variant,
        ...props,
      },
      { big, small, tiny: small, xl: big }
    );
  }
);

export { WindDirectionIcon };
