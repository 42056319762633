import { PlcContentLoader, PlcRect } from './PlcContentLoader.component';
import { PlcTextLoader } from './PlcTextLoader.component';
import React, { ComponentProps, FC } from 'react';
import { Spacing } from '@styles/Spacing.style';
import { TextStyle } from '@styles/Text.style';

type Props = ComponentProps<typeof PlcContentLoader> & {
  withLabel?: boolean;
};

export const PlcInputLoader: FC<Props> = ({ withLabel = true, height, width = 292, uniqueKey, ...rest }) => {
  const labelHeight = withLabel ? TextStyle.h6.lineHeight + Spacing.s : 0;
  const inputHeight = 64;
  return (
    <PlcContentLoader height={height || labelHeight + inputHeight} uniqueKey={uniqueKey} width={width} {...rest}>
      {withLabel && <PlcTextLoader variant="h6" width={0.4 * Number(width)} />}
      <PlcRect height={inputHeight} rx={0} ry={0} width={width} y={labelHeight} />
    </PlcContentLoader>
  );
};
